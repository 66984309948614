import * as main from "./scss/main.scss";
import { inject } from "@vercel/analytics";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./fable_modules/Fable.Promise.3.1.3/Promise.fs.js";
import { promise } from "./fable_modules/Fable.Promise.3.1.3/PromiseImpl.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.0/Choice.js";
import { printf, toConsole } from "./fable_modules/fable-library.3.7.0/String.js";
import { ProgramModule_run } from "./fable_modules/Fable.Elmish.HMR.5.2.0/../Fable.Elmish.3.1.0/program.fs.js";
import { ProgramModule_mkProgram, ProgramModule_withConsoleTrace } from "./fable_modules/Fable.Elmish.3.1.0/program.fs.js";
import { Program_withReactBatched } from "./fable_modules/Fable.Elmish.HMR.5.2.0/../Fable.Elmish.React.3.0.1/react.fs.js";
import { ProgramModule_toNavigable } from "./fable_modules/Fable.Elmish.HMR.5.2.0/../Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { parseHash } from "./fable_modules/Fable.Elmish.Browser.3.0.4/parser.fs.js";
import { pageParser } from "./Router.fs.js";
import { View_root, State_update, State_init, State_setRoute } from "./Main.fs.js";


inject();

(function () {
    let pr_1;
    const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => (fetch("https://vapi2.iotpool.fr/version", {
        method: "GET",
        mode: "cors",
    }).then((_arg1) => {
        const response = _arg1;
        return (response.ok) ? (response.text().then((_arg2) => (Promise.resolve(new FSharpResult$2(0, _arg2))))) : (response.text().then((_arg3) => (Promise.resolve(new FSharpResult$2(1, new Error(_arg3))))));
    }))));
    pr_1 = (pr.then((result) => {
        if (result.tag === 1) {
            throw (new Error("can\u0027t get version number from server"));
        }
        else {
            const realversion = result.fields[0];
            if ("2.4.5" !== realversion) {
                toConsole(printf("Version mismatch %s vs %s: reloading"))("2.4.5")(realversion);
                window.location.reload(true);
            }
            else {
                toConsole(printf("Version loaded is %s"))(realversion);
                ProgramModule_run(ProgramModule_withConsoleTrace(Program_withReactBatched("elmish-app", ProgramModule_toNavigable((location_1) => parseHash(pageParser, location_1), (optRoute, model_2) => State_setRoute(optRoute, model_2), ProgramModule_mkProgram((location) => State_init(location), (msg, model) => State_update(msg, model), (model_1, dispatch) => View_root(model_1, dispatch))))));
            }
        }
    }));
    void pr_1;
})();

