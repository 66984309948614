import * as React from "react";
import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";

type KeyValue =
  | { label: string; value: string };

type Props = {
  list: KeyValue[];
  onSelect: (values: { value: string; label: string }[]) => void;
  customLabel: string
  alreadySelected?: { value: string; label: string }[];
};

const IOTMultiSelect = ({ list, onSelect, customLabel, alreadySelected }: Props) => {
  const [selected, setSelected] = useState<
    { value: string; label: string }[]>([]);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(list)
    setSelected(alreadySelected ? alreadySelected : [])
  }, [list, alreadySelected]);

  const customValueRendererLocations = (
    selected: { label: string; value: string }[],
    _options: any
  ) => {
    return selected.length
      ? selected.length > 5
        ? `✔️ ${selected.length} élément.s sélectionné.s`
        : selected.map(({ label }) => "✔️ " + label.substring(0, 8) + "...")
      : customLabel;
  };

  return (
    <MultiSelect
      options={options}
      value={selected}
      onChange={(values: { value: string; label: string }[]) => {
        setSelected(values);
        onSelect(values);
      }}
      labelledBy="Sélectionnez les capteurs"
      valueRenderer={customValueRendererLocations}
      isLoading={options.length === 0}
      overrideStrings={{
        selectSomeItems: "Sélectionnez les capteurs",
        allItemsAreSelected: "Tous les capteurs sont sélectionnés",
        selectAll: "Tout sélectionner",
        selectAllFiltered: "Tout sélectionner (filtrés)",
        search: "Rechercher",
      }}
    />
  );
}

export default IOTMultiSelect;